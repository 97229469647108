let allCities = [
    'Dhahran',
    'Dammam',
    'Khobar',
    'Makkah',
    'Jubail',
    'Jeddah',
    'Taif',
    'Riyadh',
    'Tabouk',
    'Al-Madinah Al-Monawarah',
    'Yanbu',
    'Abha',
    'Jizan',
    'Al Ehsaa',
    'Al Kharj',
    'Al Dalem',
    'Hotat Bani Tamim',
    'Al Hareeq',
    'Al Aflag',
    'Al Khamseen',
    'As Sulayyil',
    'Harmelah',
    'Thadiq',
    'Ragba',
    'Dhurma',
    'Muzahmiyya',
    'Shaqraa',
    'Dwadmi',
    'Al Gweiiyyah',
    'Afif',
    'Al Majmaah',
    'Zulfi',
    'Al Artaweyah',
    'Unaizah',
    'Buraidah',
    'Ar Rass',
    'Wadi Al Dawasir',
    'Um Al Sahek',
    'Qatif',
    'Khafji',
    'Abqaiq',
    'Al Thuqbah',
    'Saihat',
    'Ras Tanura',
    'Al Hufuf',
    'Al Mubarraz',
    'Uqair',
    'Al Oyoon',
    'Hafr Al Batin',
    'Qaisumah',
    'King Khalid City',
    'Safwa City',
    'Hail',
    'Buq A',
    'Gatgat',
    'Al Khutt',
    'Sakakah',
    'Dumat Al Jandal',
    'Arar',
    'Turaif',
    'Gurayat',
    'Rafha',
    'Al Jouf',
    'Hala Ammr',
    'Al Wajh',
    'Haqil',
    'Tayma',
    'Duba',
    'Mastoorah',
    'Dahaban',
    'Al Lith',
    'Rabigh',
    'Al Qunfudhah',
    'Az Zaimah',
    'Al Ula',
    'Badr',
    'Sabt Al Alaya',
    'Al Baha',
    'Biljurashi',
    'Bishah',
    'Khamis Mushayt',
    'Al Namas',
    'Tanomah',
    'Farasan City',
    'Najran',
    'Sharurah',
    'Al Bekeria',
    'Algat',
    'Abu Arish',
    'Aseer',
    'Dahran Aljanoob',
    'Mahayel Asser',
    'Qalwa',
    'Sarat Obaid',
    'Damad',
    'Mahd Al Dhahab',
    'Turbah',
    'Murat',
    'Alqaseb',
    'Sajer',
    'Alkhaserah',
    'Rumah',
    'Shaweyah',
    'Sider',
    'Alrumihiyah',
    'Albadai',
    'Almidhnab',
    'Alabdaliyah',
    'Albukayriah',
    'Riyad Alkhabra',
    'Alasyah',
    'Shary',
    'Alfawarah',
    'Sayah Almulkhim',
    'Aqlat Alsuqor',
    'Almahany',
    'Albeteen',
    'Alshabikah',
    'Madraj',
    'Alnugayrah',
    'Aldulimiyah',
    'Albateraa',
    'Alqareen',
    'Althebiyah',
    'Thadej',
    'Alnabhaniyah',
    'Rawda Qerdan',
    'Qereta',
    'Aban Alahmar',
    'Delhnah',
    'Almitiwi',
    'Almarmuthah',
    'Um Hazem',
    'Alaqeer',
    'Alghahya',
    'Gheleaa Resheed',
    'Ghareyah',
    'Rafay Allaheb',
    'Qebah',
    'Alkhabra',
    'Rafay Alhagrah',
    'Alsser',
    'Tharmada',
    'Halban',
    'Malham',
    'Alqawarh',
    'Aljmsh',
    'Albjadih',
    'Rahima',
    'Nuayriyah',
    'Alsafania',
    'Natta',
    'Qariah',
    'Qura Alehsaa',
    'Salwa',
    'Haradh',
    'Aindar',
    'Alrukai',
    'Om Quleb',
    'Alssadawi',
    'Alsaira',
    'Alhulaifa',
    'Muqaq',
    'Tabah',
    'Alhait',
    'Qura Hail',
    'Jubbah',
    'Trubah Hail',
    'Alshmly',
    'Alrawdah',
    'Alkahfah',
    'Alslemy',
    'Alshinan',
    'Tabarjal',
    'Qaraa',
    'Swair',
    'Hudeeb',
    'Aladhara',
    'Allqaet',
    'Alshweahtia',
    'Zalum',
    'Albada',
    'Sharma',
    'Almowaileh',
    'Alqahzh',
    'Alkayal',
    'Mugna',
    'Alkhraibeh',
    'Al Bir',
    'Shawag',
    'Alkulaibah',
    'Aldaisah',
    'Almaazm',
    'Alkurmah',
    'Turbath Altaif',
    'Bani Malik',
    'Raniah',
    'Almuwayh',
    'Dhalim',
    'Bahrah',
    'Usfan',
    'Abu Rakah',
    'Balharth',
    'Qaya',
    'Turah Thaqaf',
    'Qazayl',
    'Kulais',
    'Alkamil',
    'Mudrikah',
    'Aljumum',
    'Alaqiq',
    'Alsharayi',
    'Yalamlam',
    'Almahd',
    'Alhanakia',
    'Alhaso',
    'Althamad',
    'Alamg',
    'Alshagran',
    'Almulaylih',
    'Suerekaya',
    'Al Furaysh',
    'Wadi Alfare',
    'Khaybar',
    'Selselah',
    'Suwaydrah',
    'Thurb',
    'Umluj',
    'Alwastah',
    'Almesejeed',
    'Almandaq',
    'Bni Hsan',
    'Doas',
    'Alqari',
    'Makhwah',
    'Tathleeth',
    'Ahad Refaidah',
    'Muhayil',
    'Rijal Alma',
    'Bani-Amer',
    'Al-Majardah',
    'Qanaa Walbahar',
    'Al Rabuaah',
    'Al Shugaari',
    'Baysh - Ad Darb - Alshqeaq - Alhaque - Ar Rayth',
    'Fayfa',
    'Sabya - Al-Idabi',
    'Samtah - Altawal',
    'Aldayer Bani Malek',
    'Hroob',
    'Ahad Almusarihah - Alkhoaba',
    'Alabilah',
    'Bader Alganob',
    'Alwadiah',
    'Habona',
    'Yadma',
  ];
  let citiesListAr = [
    'الرياض',
    'الظهران',
    'الدمام',
    'الخبر',
    'مكة المكرمة',
    'الجبيل',
    'جدة',
    'الطائف',
    'تبوك',
    'المدينة المنورة',
    'ينبع',
    'أبها',
    'جيزان',
    'الاحساء',
    'الخرج',
    'الدلم',
    'حوطه بني تميم',
    'الحريق',
    'الأفلاج',
    'الخماسين',
    'السليل',
    'حريملاء',
    'ثادق',
    'رغبة',
    'ضرما',
    'المزاحمية',
    'شقراء',
    'الدوادمي',
    'القويعية',
    'عفيف',
    'المجمعة',
    'الزلفي',
    'الأرطاوية',
    'عنيزة',
    'بريدة',
    'الرس',
    'وادي الدواسر',
    'أم الساهك',
    'القطيف',
    'الخفجي',
    'أبقيق',
    'الثقبة',
    'سيهات',
    'رأس تنورة',
    'الهفوف',
    'المبرز',
    'العقير',
    'العيون',
    'حفر الباطن',
    'القيصومة',
    'مدينه الملك خالد العسكريه',
    'صفوى',
    'حائل',
    'بقعاء',
    'ضرغط',
    'الخط',
    'سكاكا',
    'دومة الجندل',
    'عرعر',
    'طريف',
    'القريات',
    'رفحة',
    'الجوف',
    'حاله عمار',
    'الوجه',
    'حقل',
    'تيماء',
    'ضباء',
    'مستورة',
    'ذهبان',
    'الليث',
    'رابغ',
    'القنفذه',
    'الزيمة',
    'العلا',
    'بدر',
    'سبت العلايا',
    'الباحة',
    'بلجرشي',
    'بيشة',
    'خميس مشيط',
    'النماص',
    'تنومة',
    'مدينة فراسان',
    'نجران',
    'شرورة',
    'البيكيرية',
    'الغاط',
    'ابو عريش',
    'عسير',
    'ظهران الجنوب',
    'محايل عسير',
    'قلوه',
    'سراة عبيدة',
    'ضمد',
    'مهد الذهب',
    'تربة',
    'مرات',
    'القصب',
    'ساجر',
    'الخاصرة',
    'رماح',
    'شوية',
    'سدير',
    'الرميحية',
    'البدائع',
    'المذنب',
    'العبدلية',
    'البكيرية',
    'رياض الخبراء',
    'الأسياح',
    'شري',
    'الفوارة',
    'صياح الملخم',
    'عقلة الصقور',
    'المحاني',
    'البطين',
    'الشبيكة',
    'مدرج',
    'النقيرة',
    'الدليمية',
    'البتراء',
    'القرين',
    'الذيبية',
    'ثادج',
    'النبهانيه',
    'روضه قردان',
    'قريطه',
    'ابان الاحمر',
    'دخنة',
    'المطيوي',
    'المرموثة',
    'أم حزم',
    'العاقر',
    'الغضياء',
    'ضليع رشيد',
    'ضريه',
    'رفائع اللهيب',
    'قبه',
    'الخبرا',
    'رفائع الحجرة',
    'السر',
    'ثرمداء',
    'حلبان',
    'ملهم',
    'القوارة',
    'الجمش',
    'البجادية',
    'رحيمة',
    'النعيرية',
    'السفانية',
    'نطاع',
    'قريه',
    'قرى الإحساء',
    'سلوى',
    'حرض',
    'عين دار',
    'الرقعي',
    'ام قليب',
    'الصداوي',
    'السعيرة',
    'الحليفه',
    'موقق',
    'طابه',
    'الحايط',
    'قرى حائل',
    'جبه',
    'تربة-حائل',
    'الشملي',
    'الروضة',
    'الكهفة',
    'السليمي',
    'الشنان',
    'طبرجل',
    'قارا',
    'صوير',
    'هديب',
    'الاضارع',
    'اللقائط',
    'الشويحطيه',
    'زلوم',
    'البدع',
    'شرما',
    'المويلح',
    'القحزة',
    'قيال',
    'مقنا',
    'الخريبة',
    'البئر',
    'شواق',
    'القليبه',
    'الديسه',
    'المعظم',
    'الخرمة',
    'تربة-الطائف',
    'بنى مالك',
    'رنيه',
    'المويه',
    'ظلم',
    'بحرة',
    'عسفان',
    'أبو راكه',
    'بالحارث',
    'قياء',
    'ترعة ثقيف',
    'غزايل',
    'خليص',
    'الكامل',
    'مدركه',
    'الجموم',
    'العقيق',
    'الشرائع',
    'يلملم',
    'المهد',
    'الحناكية',
    'الحسو',
    'الثمد',
    'العمق',
    'الشقران',
    'المليليح',
    'السويرقيه',
    'الفريش',
    'وادي الفرع',
    'خيبر',
    'الصلصلة',
    'الصويدرة',
    'ثرب',
    'املج',
    'الواسطة',
    'المسيجيد',
    'المندق',
    'بني حسن',
    'دوس',
    'القري',
    'المخواه',
    'تثليث',
    'احد رفيدة',
    'محائل',
    'رجال ألمع',
    'بني عمرو',
    'المجاردة',
    'قناءوالبحر',
    'الربوعة',
    'الشقيري',
    'بيش - الدرب - الشقيق - الحقو - الريث',
    'فيفا',
    'صبيا -العيدابي',
    'صامطة - الطوال',
    'الداير بني مالك',
    'هروب',
    'احد المسارحة - الخوبة',
    'العبيله',
    'بدر الجنوب',
    'الوديعة',
    'حبونا',
    'يدمه',
  ];
  export const getCity = (lang) => {
    // let lang = localStorage.getItem('language');

    if (lang == 'ar')
    {
        return citiesListAr
    }
    else{
        return allCities
    }
   
   }
   export default {
     getCity
 }