let allCountries = [
    {
        "name": "Afghanistan",
        "dialCode": "+93",
        "isoCode": "AF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
        "name_ar": "أفغانستان"
    },
    {
        "name": "Albania",
        "dialCode": "+355",
        "isoCode": "AL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
        "name_ar": "ألبانيا"
    },
    {
        "name": "Algeria",
        "dialCode": "+213",
        "isoCode": "DZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
        "name_ar": "الجزائر"
    },

    {
        "name": "Andorra",
        "dialCode": "+376",
        "isoCode": "AD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
        "name_ar": "أندورا"
    },
    {
        "name": "Angola",
        "dialCode": "+244",
        "isoCode": "AO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
        "name_ar": "أنغولا"
    },

    {
        "name": "Antigua and Barbuda",
        "dialCode": "+1268",
        "isoCode": "AG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
        "name_ar": "أنتيغوا وباربودا"
    },
    {
        "name": "Argentina",
        "dialCode": "+54",
        "isoCode": "AR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
        "name_ar": "الأرجنتين"
    },
    {
        "name": "Armenia",
        "dialCode": "+374",
        "isoCode": "AM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
        "name_ar": "أرمينيا"
    },
    {
        "name": "Aruba",
        "dialCode": "+297",
        "isoCode": "AW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
        "name_ar": "آروبا"
    },
    {
        "name": "Australia",
        "dialCode": "+61",
        "isoCode": "AU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
        "name_ar": "أستراليا"
    },
    {
        "name": "Austria",
        "dialCode": "+43",
        "isoCode": "AT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
        "name_ar": "النمسا"
    },
    {
        "name": "Azerbaijan",
        "dialCode": "+994",
        "isoCode": "AZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
        "name_ar": "أذربيجان"
    },
    {
        "name": "Bahamas",
        "dialCode": "+1242",
        "isoCode": "BS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
        "name_ar": "باهاماس"
    },
    {
        "name": "Bahrain",
        "dialCode": "+973",
        "isoCode": "BH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
        "name_ar": "البحرين"
    },
    {
        "name": "Bangladesh",
        "dialCode": "+880",
        "isoCode": "BD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
        "name_ar": "بنغلاديش"
    },
    {
        "name": "Barbados",
        "dialCode": "+1246",
        "isoCode": "BB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
        "name_ar": "باربادوس"
    },
    {
        "name": "Belarus",
        "dialCode": "+375",
        "isoCode": "BY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
        "name_ar": "بيلاروس"
    },
    {
        "name": "Belgium",
        "dialCode": "+32",
        "isoCode": "BE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
        "name_ar": "بلجيكا"
    },
    {
        "name": "Belize",
        "dialCode": "+501",
        "isoCode": "BZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
        "name_ar": "بليز"
    },
    {
        "name": "Benin",
        "dialCode": "+229",
        "isoCode": "BJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
        "name_ar": "بنين"
    },

    {
        "name": "Bhutan",
        "dialCode": "+975",
        "isoCode": "BT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
        "name_ar": "بوتان"
    },
    {
        "name": "Bolivia",
        "dialCode": "+591",
        "isoCode": "BO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
        "name_ar": "بوليفيا"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dialCode": "+387",
        "isoCode": "BA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
        "name_ar": "البوسنة والهرسك"
    },
    {
        "name": "Botswana",
        "dialCode": "+267",
        "isoCode": "BW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
        "name_ar": "بوتسوانا"
    },
    {
        "name": "Brazil",
        "dialCode": "+55",
        "isoCode": "BR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
        "name_ar": "البرازيل"
    },

    {
        "name": "Brunei Darussalam",
        "dialCode": "+673",
        "isoCode": "BN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
        "name_ar": "بروناي"
    },
    {
        "name": "Bulgaria",
        "dialCode": "+359",
        "isoCode": "BG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
        "name_ar": "بلغاريا"
    },
    {
        "name": "Burkina Faso",
        "dialCode": "+226",
        "isoCode": "BF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
        "name_ar": "بوركينا فاسو"
    },
    {
        "name": "Burundi",
        "dialCode": "+257",
        "isoCode": "BI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
        "name_ar": "بوروندي"
    },
    {
        "name": "Cambodia",
        "dialCode": "+855",
        "isoCode": "KH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
        "name_ar": "كمبوديا"
    },
    {
        "name": "Cameroon",
        "dialCode": "+237",
        "isoCode": "CM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
        "name_ar": "الكاميرون"
    },
    {
        "name": "Canada",
        "dialCode": "+1",
        "isoCode": "CA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
        "name_ar": "كندا"
    },

    {
        "name": "Central African Republic",
        "dialCode": "+236",
        "isoCode": "CF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
        "name_ar": "جمهورية أفريقيا الوسطى"
    },
    {
        "name": "Chad",
        "dialCode": "+235",
        "isoCode": "TD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
        "name_ar": "تشاد"
    },
    {
        "name": "Chile",
        "dialCode": "+56",
        "isoCode": "CL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
        "name_ar": "تشيلي"
    },
    {
        "name": "China",
        "dialCode": "+86",
        "isoCode": "CN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
        "name_ar": "الصين"
    },

    {
        "name": "Colombia",
        "dialCode": "+57",
        "isoCode": "CO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
        "name_ar": "كولومبيا"
    },
    {
        "name": "Comoros",
        "dialCode": "+269",
        "isoCode": "KM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
        "name_ar": "جزر القمر"
    },
    {
        "name": "Congo",
        "dialCode": "+242",
        "isoCode": "CG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
        "name_ar": "جمهورية الكونغو"
    },

    {
        "name": "Costa Rica",
        "dialCode": "+506",
        "isoCode": "CR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
        "name_ar": "كوستاريكا"
    },
    {
        "name": "Croatia",
        "dialCode": "+385",
        "isoCode": "HR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
        "name_ar": "كرواتيا"
    },
    {
        "name": "Cuba",
        "dialCode": "+53",
        "isoCode": "CU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
        "name_ar": "كوبا"
    },
    {
        "name": "Cyprus",
        "dialCode": "+357",
        "isoCode": "CY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
        "name_ar": "قبرص"
    },
    {
        "name": "Czech Republic",
        "dialCode": "+420",
        "isoCode": "CZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
        "name_ar": "جمهورية التشيك"
    },
    {
        "name": "Democratic Republic of the Congo",
        "dialCode": "+243",
        "isoCode": "CD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
        "name_ar": "جمهورية الكونغو الديمقراطية"
    },
    {
        "name": "Denmark",
        "dialCode": "+45",
        "isoCode": "DK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
        "name_ar": "الدنمارك"
    },
    {
        "name": "Djibouti",
        "dialCode": "+253",
        "isoCode": "DJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
        "name_ar": "جيبوتي"
    },
    {
        "name": "Dominica",
        "dialCode": "+1767",
        "isoCode": "DM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
        "name_ar": "دومينيكا"
    },
    {
        "name": "Dominican Republic",
        "dialCode": "+1849",
        "isoCode": "DO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
        "name_ar": "جمهورية الدومينيكان"
    },
    {
        "name": "Ecuador",
        "dialCode": "+593",
        "isoCode": "EC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
        "name_ar": "الإكوادور"
    },
    {
        "name": "Egypt",
        "dialCode": "+20",
        "isoCode": "EG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
        "name_ar": "مصر"
    },
    {
        "name": "El Salvador",
        "dialCode": "+503",
        "isoCode": "SV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
        "name_ar": "السلفادور"
    },
    {
        "name": "Equatorial Guinea",
        "dialCode": "+240",
        "isoCode": "GQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
        "name_ar": "غينيا الاستوائية"
    },
    {
        "name": "Eritrea",
        "dialCode": "+291",
        "isoCode": "ER",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
        "name_ar": "إريتريا"
    },
    {
        "name": "Estonia",
        "dialCode": "+372",
        "isoCode": "EE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
        "name_ar": "إستونيا"
    },
    {
        "name": "Eswatini",
        "dialCode": "+268",
        "isoCode": "SZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
        "name_ar": "إسواتيني"
    },
    {
        "name": "Ethiopia",
        "dialCode": "+251",
        "isoCode": "ET",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
        "name_ar": "إثيوبيا"
    },

    {
        "name": "Fiji",
        "dialCode": "+679",
        "isoCode": "FJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
        "name_ar": "فيجي"
    },
    {
        "name": "Finland",
        "dialCode": "+358",
        "isoCode": "FI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
        "name_ar": "فنلندا"
    },
    {
        "name": "France",
        "dialCode": "+33",
        "isoCode": "FR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
        "name_ar": "فرنسا"
    },
    {
        "name": "French Polynesia",
        "dialCode": "+689",
        "isoCode": "PF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
        "name_ar": "بولينيزيا الفرنسية"
    },
    {
        "name": "Gabon",
        "dialCode": "+241",
        "isoCode": "GA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
        "name_ar": "الغابون"
    },
    {
        "name": "Gambia",
        "dialCode": "+220",
        "isoCode": "GM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
        "name_ar": "غامبيا"
    },
    {
        "name": "Georgia",
        "dialCode": "+995",
        "isoCode": "GE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
        "name_ar": "جورجيا"
    },
    {
        "name": "Germany",
        "dialCode": "+49",
        "isoCode": "DE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
        "name_ar": "ألمانيا"
    },
    {
        "name": "Ghana",
        "dialCode": "+233",
        "isoCode": "GH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
        "name_ar": "غانا"
    },

    {
        "name": "Greece",
        "dialCode": "+30",
        "isoCode": "GR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
        "name_ar": "اليونان"
    },
    {
        "name": "Greenland",
        "dialCode": "+299",
        "isoCode": "GL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
        "name_ar": "غرينلاند"
    },
    {
        "name": "Grenada",
        "dialCode": "+1473",
        "isoCode": "GD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
        "name_ar": "غرينادا"
    },
    {
        "name": "Guam",
        "dialCode": "+1671",
        "isoCode": "GU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
        "name_ar": "غوام"
    },
    {
        "name": "Guatemala",
        "dialCode": "+502",
        "isoCode": "GT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
        "name_ar": "غواتيمالا"
    },
    {
        "name": "Guernsey",
        "dialCode": "+44-1481",
        "isoCode": "GG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
        "name_ar": "غيرنسي"
    },
    {
        "name": "Guinea",
        "dialCode": "+224",
        "isoCode": "GN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
        "name_ar": "غينيا"
    },
    {
        "name": "Guinea-Bissau",
        "dialCode": "+245",
        "isoCode": "GW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
        "name_ar": "غينيا بيساو"
    },
    {
        "name": "Guyana",
        "dialCode": "+592",
        "isoCode": "GY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
        "name_ar": "غيانا"
    },
    {
        "name": "Haiti",
        "dialCode": "+509",
        "isoCode": "HT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
        "name_ar": "هايتي"
    },
    {
        "name": "Honduras",
        "dialCode": "+504",
        "isoCode": "HN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
        "name_ar": "هندوراس"
    },
    {
        "name": "Hong Kong",
        "dialCode": "+852",
        "isoCode": "HK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
        "name_ar": "هونج كونج"
    },
    {
        "name": "Hungary",
        "dialCode": "+36",
        "isoCode": "HU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
        "name_ar": "المجر"
    },
    {
        "name": "Iceland",
        "dialCode": "+354",
        "isoCode": "IS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
        "name_ar": "آيسلندا"
    },
    {
        "name": "India",
        "dialCode": "+91",
        "isoCode": "IN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
        "name_ar": "الهند"
    },
    {
        "name": "Indonesia",
        "dialCode": "+62",
        "isoCode": "ID",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
        "name_ar": "إندونيسيا"
    },
    {
        "name": "Iran",
        "dialCode": "+98",
        "isoCode": "IR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
        "name_ar": "إيران"
    },
    {
        "name": "Iraq",
        "dialCode": "+964",
        "isoCode": "IQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
        "name_ar": "العراق"
    },
    {
        "name": "Ireland",
        "dialCode": "+353",
        "isoCode": "IE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
        "name_ar": "أيرلندا"
    },

    {
        "name": "Israel",
        "dialCode": "+972",
        "isoCode": "IL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
        "name_ar": "إسرائيل"
    },
    {
        "name": "Italy",
        "dialCode": "+39",
        "isoCode": "IT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
        "name_ar": "إيطاليا"
    },
    {
        "name": "Jamaica",
        "dialCode": "+1876",
        "isoCode": "JM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
        "name_ar": "جامايكا"
    },
    {
        "name": "Japan",
        "dialCode": "+81",
        "isoCode": "JP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
        "name_ar": "اليابان"
    },
    {
        "name": "Jersey",
        "dialCode": "+44-1534",
        "isoCode": "JE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
        "name_ar": "جيرسي"
    },
    {
        "name": "Jordan",
        "dialCode": "+962",
        "isoCode": "JO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
        "name_ar": "الأردن"
    },
    {
        "name": "Kazakhstan",
        "dialCode": "+77",
        "isoCode": "KZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
        "name_ar": "كازاخستان"
    },
    {
        "name": "Kenya",
        "dialCode": "+254",
        "isoCode": "KE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
        "name_ar": "كينيا"
    },
    {
        "name": "Kiribati",
        "dialCode": "+686",
        "isoCode": "KI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
        "name_ar": "كيريباتي"
    },
    {
        "name": "North Korea",
        "dialCode": "+850",
        "isoCode": "KP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
        "name_ar": "كوريا الشمالية"
    },
    {
        "name": "South Korea",
        "dialCode": "+82",
        "isoCode": "KR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
        "name_ar": "كوريا الجنوبية"
    },
    {
        "name": "Kuwait",
        "dialCode": "+965",
        "isoCode": "KW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
        "name_ar": "الكويت"
    },
    {
        "name": "Kyrgyzstan",
        "dialCode": "+996",
        "isoCode": "KG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
        "name_ar": "قيرغيزستان"
    },
    {
        "name": "Laos",
        "dialCode": "+856",
        "isoCode": "LA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
        "name_ar": "لاوس"
    },
    {
        "name": "Latvia",
        "dialCode": "+371",
        "isoCode": "LV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
        "name_ar": "لاتفيا"
    },
    {
        "name": "Lebanon",
        "dialCode": "+961",
        "isoCode": "LB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
        "name_ar": "لبنان"
    },
    {
        "name": "Lesotho",
        "dialCode": "+266",
        "isoCode": "LS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
        "name_ar": "ليسوتو"
    },
    {
        "name": "Liberia",
        "dialCode": "+231",
        "isoCode": "LR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
        "name_ar": "ليبيريا"
    },
    {
        "name": "Libya",
        "dialCode": "+218",
        "isoCode": "LY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
        "name_ar": "ليبيا"
    },
    {
        "name": "Liechtenstein",
        "dialCode": "+423",
        "isoCode": "LI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
        "name_ar": "ليختنشتاين"
    },
    {
        "name": "Lithuania",
        "dialCode": "+370",
        "isoCode": "LT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
        "name_ar": "ليتوانيا"
    },
    {
        "name": "Luxembourg",
        "dialCode": "+352",
        "isoCode": "LU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
        "name_ar": "لوكسمبورغ"
    },
    {
        "name": "Macau",
        "dialCode": "+853",
        "isoCode": "MO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
        "name_ar": "ماكاو"
    },
    {
        "name": "Madagascar",
        "dialCode": "+261",
        "isoCode": "MG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
        "name_ar": "مدغشقر"
    },
    {
        "name": "Malawi",
        "dialCode": "+265",
        "isoCode": "MW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
        "name_ar": "مالاوي"
    },
    {
        "name": "Malaysia",
        "dialCode": "+60",
        "isoCode": "MY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
        "name_ar": "ماليزيا"
    },
    {
        "name": "Maldives",
        "dialCode": "+960",
        "isoCode": "MV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
        "name_ar": "جزر المالديف"
    },
    {
        "name": "Mali",
        "dialCode": "+223",
        "isoCode": "ML",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
        "name_ar": "مالي"
    },
    {
        "name": "Malta",
        "dialCode": "+356",
        "isoCode": "MT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
        "name_ar": "مالطا"
    },
    {
        "name": "Marshall Islands",
        "dialCode": "+692",
        "isoCode": "MH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
        "name_ar": "جزر مارشال"
    },
    {
        "name": "Martinique",
        "dialCode": "+596",
        "isoCode": "MQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
        "name_ar": "مارتينيك"
    },
    {
        "name": "Mauritania",
        "dialCode": "+222",
        "isoCode": "MR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
        "name_ar": "موريتانيا"
    },
    {
        "name": "Mauritius",
        "dialCode": "+230",
        "isoCode": "MU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
        "name_ar": "موريشيوس"
    },
    {
        "name": "Mexico",
        "dialCode": "+52",
        "isoCode": "MX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
        "name_ar": "المكسيك"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dialCode": "+691",
        "isoCode": "FM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
        "name_ar": "ولايات ميكرونيسيا المتحدة"
    },
    {
        "name": "Moldova",
        "dialCode": "+373",
        "isoCode": "MD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
        "name_ar": "مولدوفا"
    },
    {
        "name": "Monaco",
        "dialCode": "+377",
        "isoCode": "MC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
        "name_ar": "موناكو"
    },
    {
        "name": "Mongolia",
        "dialCode": "+976",
        "isoCode": "MN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
        "name_ar": "منغوليا"
    },
    {
        "name": "Montenegro",
        "dialCode": "+382",
        "isoCode": "ME",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
        "name_ar": "الجبل الأسود"
    },
    {
        "name": "Montserrat",
        "dialCode": "+1664",
        "isoCode": "MS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
        "name_ar": "مونتسيرات"
    },
    {
        "name": "Morocco",
        "dialCode": "+212",
        "isoCode": "MA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
        "name_ar": "المغرب"
    },
    {
        "name": "Mozambique",
        "dialCode": "+258",
        "isoCode": "MZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
        "name_ar": "موزمبيق"
    },
    {
        "name": "Myanmar",
        "dialCode": "+95",
        "isoCode": "MM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
        "name_ar": "ميانمار"
    },
    {
        "name": "Namibia",
        "dialCode": "+264",
        "isoCode": "NA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
        "name_ar": "ناميبيا"
    },
    {
        "name": "Nauru",
        "dialCode": "+674",
        "isoCode": "NR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
        "name_ar": "ناورو"
    },
    {
        "name": "Nepal",
        "dialCode": "+977",
        "isoCode": "NP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
        "name_ar": "نيبال"
    },
    {
        "name": "Netherlands",
        "dialCode": "+31",
        "isoCode": "NL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
        "name_ar": "هولندا"
    },
    {
        "name": "New Zealand",
        "dialCode": "+64",
        "isoCode": "NZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
        "name_ar": "نيوزيلندا"
    },
    {
        "name": "Nicaragua",
        "dialCode": "+505",
        "isoCode": "NI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
        "name_ar": "نيكاراغوا"
    },
    {
        "name": "Niger",
        "dialCode": "+227",
        "isoCode": "NE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
        "name_ar": "النيجر"
    },
    {
        "name": "Nigeria",
        "dialCode": "+234",
        "isoCode": "NG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
        "name_ar": "نيجيريا"
    },

    {
        "name": "Norfolk Island",
        "dialCode": "+672",
        "isoCode": "NF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
        "name_ar": "جزيرة نورفولك"
    },
    {
        "name": "North Macedonia",
        "dialCode": "+389",
        "isoCode": "MK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mk.svg",
        "name_ar": "مقدونيا"
    },
    {
        "name": "Northern Mariana Islands",
        "dialCode": "+1670",
        "isoCode": "MP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
        "name_ar": "جزر مريانا الشمالية"
    },
    {
        "name": "Norway",
        "dialCode": "+47",
        "isoCode": "NO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
        "name_ar": "النرويج"
    },
    {
        "name": "Oman",
        "dialCode": "+968",
        "isoCode": "OM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
        "name_ar": "عمان"
    },
    {
        "name": "Pakistan",
        "dialCode": "+92",
        "isoCode": "PK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
        "name_ar": "باكستان"
    },
    {
        "name": "Palau",
        "dialCode": "+680",
        "isoCode": "PW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
        "name_ar": "بالاو"
    },
    {
        "name": "Palestine",
        "dialCode": "+970",
        "isoCode": "PS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
        "name_ar": "فلسطين"
    },
    {
        "name": "Panama",
        "dialCode": "+507",
        "isoCode": "PA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
        "name_ar": "بنما"
    },
    {
        "name": "Papua New Guinea",
        "dialCode": "+675",
        "isoCode": "PG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
        "name_ar": "بابوا غينيا الجديدة"
    },
    {
        "name": "Paraguay",
        "dialCode": "+595",
        "isoCode": "PY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
        "name_ar": "باراغواي"
    },
    {
        "name": "Peru",
        "dialCode": "+51",
        "isoCode": "PE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
        "name_ar": "بيرو"
    },
    {
        "name": "Philippines",
        "dialCode": "+63",
        "isoCode": "PH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
        "name_ar": "الفلبين"
    },

    {
        "name": "Poland",
        "dialCode": "+48",
        "isoCode": "PL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
        "name_ar": "بولندا"
    },
    {
        "name": "Portugal",
        "dialCode": "+351",
        "isoCode": "PT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
        "name_ar": "البرتغال"
    },
    {
        "name": "Puerto Rico",
        "dialCode": "+1939",
        "isoCode": "PR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
        "name_ar": "بورتيريكو"
    },
    {
        "name": "Qatar",
        "dialCode": "+974",
        "isoCode": "QA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
        "name_ar": "قطر"
    },
    {
        "name": "Romania",
        "dialCode": "+40",
        "isoCode": "RO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
        "name_ar": "رومانيا"
    },
    {
        "name": "Russia",
        "dialCode": "+7",
        "isoCode": "RU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
        "name_ar": "روسيا"
    },
    {
        "name": "Rwanda",
        "dialCode": "+250",
        "isoCode": "RW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
        "name_ar": "رواندا"
    },

    {
        "name": "Saint Kitts and Nevis",
        "dialCode": "+1869",
        "isoCode": "KN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
        "name_ar": "سانت كيتس ونيفيس"
    },
    {
        "name": "Saint Lucia",
        "dialCode": "+1758",
        "isoCode": "LC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
        "name_ar": "سانت لوسيا"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "+1784",
        "isoCode": "VC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
        "name_ar": "سانت فينسنت والغرينادين"
    },
    {
        "name": "Samoa",
        "dialCode": "+685",
        "isoCode": "WS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
        "name_ar": "ساموا"
    },
    {
        "name": "San Marino",
        "dialCode": "+378",
        "isoCode": "SM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
        "name_ar": "سان مارينو"
    },
    {
        "name": "Sao Tome and Principe",
        "dialCode": "+239",
        "isoCode": "ST",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
        "name_ar": "ساو تومي وبرينسيب"
    },
    {
        "name": "Saudi Arabia",
        "dialCode": "+966",
        "isoCode": "SA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
        "name_ar": "السعودية"
    },
    {
        "name": "Senegal",
        "dialCode": "+221",
        "isoCode": "SN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
        "name_ar": "السنغال"
    },
    {
        "name": "Serbia",
        "dialCode": "+381",
        "isoCode": "RS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
        "name_ar": "صربيا"
    },
    {
        "name": "Seychelles",
        "dialCode": "+248",
        "isoCode": "SC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
        "name_ar": "سيشل"
    },
    {
        "name": "Sierra Leone",
        "dialCode": "+232",
        "isoCode": "SL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
        "name_ar": "سيراليون"
    },
    {
        "name": "Singapore",
        "dialCode": "+65",
        "isoCode": "SG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
        "name_ar": "سنغافورة"
    },

    {
        "name": "Slovakia",
        "dialCode": "+421",
        "isoCode": "SK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
        "name_ar": "سلوفاكيا"
    },
    {
        "name": "Slovenia",
        "dialCode": "+386",
        "isoCode": "SI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
        "name_ar": "سلوفينيا"
    },
    {
        "name": "Solomon Islands",
        "dialCode": "+677",
        "isoCode": "SB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
        "name_ar": "جزر سليمان"
    },
    {
        "name": "Somalia",
        "dialCode": "+252",
        "isoCode": "SO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
        "name_ar": "الصومال"
    },
    {
        "name": "South Africa",
        "dialCode": "+27",
        "isoCode": "ZA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
        "name_ar": "جنوب أفريقيا"
    },
    {
        "name": "South Sudan",
        "dialCode": "+211",
        "isoCode": "SS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
        "name_ar": "جنوب السودان"
    },
    {
        "name": "Spain",
        "dialCode": "+34",
        "isoCode": "ES",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
        "name_ar": "إسبانيا"
    },
    {
        "name": "Sri Lanka",
        "dialCode": "+94",
        "isoCode": "LK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
        "name_ar": "سريلانكا"
    },
    {
        "name": "Sudan",
        "dialCode": "+249",
        "isoCode": "SD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
        "name_ar": "السودان"
    },
    {
        "name": "Suriname",
        "dialCode": "+597",
        "isoCode": "SR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
        "name_ar": "سورينام"
    },
    {
        "name": "Sweden",
        "dialCode": "+46",
        "isoCode": "SE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
        "name_ar": "السويد"
    },
    {
        "name": "Switzerland",
        "dialCode": "+41",
        "isoCode": "CH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
        "name_ar": "سويسرا"
    },
    {
        "name": "Syrian Arab Republic",
        "dialCode": "+963",
        "isoCode": "SY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
        "name_ar": "سوريا"
    },
    {
        "name": "Taiwan",
        "dialCode": "+886",
        "isoCode": "TW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
        "name_ar": "تايوان"
    },
    {
        "name": "Tajikistan",
        "dialCode": "+992",
        "isoCode": "TJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
        "name_ar": "طاجيكستان"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dialCode": "+255",
        "isoCode": "TZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
        "name_ar": "تنزانيا"
    },
    {
        "name": "Thailand",
        "dialCode": "+66",
        "isoCode": "TH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
        "name_ar": "تايلاند"
    },
    {
        "name": "Timor-Leste",
        "dialCode": "+670",
        "isoCode": "TL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
        "name_ar": "تيمور الشرقية"
    },
    {
        "name": "Togo",
        "dialCode": "+228",
        "isoCode": "TG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
        "name_ar": "توغو"
    },

    {
        "name": "Tonga",
        "dialCode": "+676",
        "isoCode": "TO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
        "name_ar": "تونغا"
    },
    {
        "name": "Trinidad and Tobago",
        "dialCode": "+1868",
        "isoCode": "TT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
        "name_ar": "ترينيداد وتوباغو"
    },
    {
        "name": "Tunisia",
        "dialCode": "+216",
        "isoCode": "TN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
        "name_ar": "تونس"
    },
    {
        "name": "Turkey",
        "dialCode": "+90",
        "isoCode": "TR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
        "name_ar": "تركيا"
    },
    {
        "name": "Turkmenistan",
        "dialCode": "+993",
        "isoCode": "TM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
        "name_ar": "تركمانستان"
    },

    {
        "name": "Tuvalu",
        "dialCode": "+688",
        "isoCode": "TV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
        "name_ar": "توفالو"
    },
    {
        "name": "Uganda",
        "dialCode": "+256",
        "isoCode": "UG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
        "name_ar": "أوغندا"
    },
    {
        "name": "Ukraine",
        "dialCode": "+380",
        "isoCode": "UA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
        "name_ar": "أوكرانيا"
    },
    {
        "name": "United Arab Emirates",
        "dialCode": "+971",
        "isoCode": "AE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
        "name_ar": "الإمارات العربية المتحدة"
    },
    {
        "name": "United Kingdom",
        "dialCode": "+44",
        "isoCode": "GB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
        "name_ar": "المملكة المتحدة"
    },
    {
        "name": "United States",
        "dialCode": "+1",
        "isoCode": "US",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
        "name_ar": "الولايات المتحدة"
    },
    {
        "name": "Uruguay",
        "dialCode": "+598",
        "isoCode": "UY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
        "name_ar": "الأوروغواي"
    },
    {
        "name": "Uzbekistan",
        "dialCode": "+998",
        "isoCode": "UZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
        "name_ar": "أوزبكستان"
    },
    {
        "name": "Vanuatu",
        "dialCode": "+678",
        "isoCode": "VU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
        "name_ar": "فانواتو"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "+58",
        "isoCode": "VE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
        "name_ar": "فنزويلا"
    },
    {
        "name": "Vietnam",
        "dialCode": "+84",
        "isoCode": "VN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
        "name_ar": "فيتنام"
    },

    {
        "name": "Yemen",
        "dialCode": "+967",
        "isoCode": "YE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
        "name_ar": "اليمن"
    },
    {
        "name": "Zimbabwe",
        "dialCode": "+263",
        "isoCode": "ZW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
        "name_ar": "زيمبابوي"
    }
]
export const getCountry = () =>
{
    return allCountries
}
export const getSingleCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].flag
        }

    }
}

export const getEnCountryFromAr = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name_ar)
        {
            return allCountries[i].name
        }

    }
}

export const getArabicSingleCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].name_ar
        }

    }
}

export const getArCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].name_ar
        }

    }
}
const getArabicCountry = (val, selectedLanguage) =>
{
    if (selectedLanguage == 'ar')
    {
        for (let i = 0; i < allCountries.length; i++)
        {
            if (val == allCountries[i].name)
            {
                return allCountries[i].name_ar
            }
        }
    }
    else
    {
        return val
    }
}


export default {
    getCountry,
    getSingleCountry,
    getArCountry,
    getArabicSingleCountry,
    getEnCountryFromAr,
    getArabicCountry
}