<template>
    <v-container class="details">
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <v-sheet v-if="!success"
                 class="pa-4 pb-7 mt-16 px-6 rounded security">

            <div style=" font-size: 14px;"
                 class="font-weight-bold text-color-black pb-4 pt-1">{{ $t('Personal information') }}</div>
            <v-row class="department">
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div style="font-size: 12px;"
                         class="font-weight-bold text-black">
                        {{ $t('Full Name') }}
                    </div>
                    <v-text-field outlined
                                  type="text"
                                  placeholder="Enter Full Name"
                                  v-model="fullName"
                                  :readonly="true"
                                  solo
                                  hide-details="auto"
                                  class="mt-2"></v-text-field>

                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div style="font-size: 12px;"
                         class="font-weight-bold text-black">
                        {{ $t('Nationality') }}
                    </div>
                    <v-text-field outlined
                                  type="text"
                                  placeholder="Nationality"
                                  :readonly="true"
                                  v-model="nationality"
                                  hide-details="auto"
                                  solo
                                  class="mt-2"></v-text-field>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">

                    <div style="font-size: 12px;"
                         class="mt-5 font-weight-bold text-color-black">
                        {{ $t('ID / Iqama number') }}
                    </div>
                    <v-text-field outlined
                                  solo
                                  type="text"
                                  placeholder="Enter ID / Iqama number"
                                  v-model="legelId"
                                  :readonly="true"
                                  hide-details="auto"
                                  class="mt-2"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">

                    <div style="font-size: 12px;"
                         class="mt-5 font-weight-bold text-color-black">
                        {{ $t('Expiry date') }}
                    </div>
                    <v-text-field outlined
                                  type="text"
                                  maxlength="5"
                                  placeholder="MM-YY"
                                  v-mask="'##-####'"
                                  :readonly="true"
                                  v-model="expiry"
                                  solo
                                  hide-details="auto"
                                  class="mt-2"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-4">
                <v-col cols="12"
                       lg="6"
                       md="12"
                       sm="12">
                    <div class="text-color-black iqama"
                         style="font-size: 14px !important;">
                        {{ $t('Upload ID / Iqama copy') }}
                    </div>
                    <span style="font-size: 12px;">
                        {{ $t('Choose a document to upload. Max file size is') }} <span style="font-weight: 600;">{{
                            $t('5MB') }}</span>
                    </span>

                    <input id="fileUpload"
                           type="file"
                           value="C:\fakepath"
                           hidden
                           accept="image/jpg, image/png, application/pdf"
                           @change="uploadImg">
                    <v-card style="padding: 11px 11px;"
                            class="d-flex mt-3 uplaod-img">
                        <div class="d-flex btn"
                             @click="chooseFiles()"
                             style="cursor: pointer; border: 1px solid rgb(0, 111, 207);height: 31px;padding: 2px 9px;border-radius: 8px;">
                            <img v-if="!showLoader"
                                 src="@/assets/images/Upload-Filled.png"
                                 style="width: 15x;height: 14px;position: absolute;top: 20px;"
                                 alt="">
                            <img v-if="showLoader"
                                 src="@/assets/images/loader-1.gif"
                                 alt=""
                                 style="width: 18px;height: 18px;position: absolute;top: 16px;">
                            <button class="button text-blue font-weight-bold mx-4">
                                <span style="padding: 0px 9px;font-weight: 200;">{{ $t('Upload') }}</span>
                            </button>
                        </div>
                        <span v-if="!closeIcon"
                              class="text-color-black d-flex"
                              style="font-size: 13px;padding: 6px 9px;">
                            {{ $t(FileName) }}
                            <v-icon v-if="closeIcon">
                                {{ icons.mdiClose }}
                            </v-icon>
                        </span>

                        <div v-if="closeIcon"
                             class="text-color-black d-flex"
                             style="font-size: 13px;padding: 6px 9px;width: 100%;">
                            <div class="tooltip">
                                {{ FileName.substring(0, 15) + "..." }}
                                <span class="tooltiptext">
                                    {{ FileName }}
                                </span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon style="cursor: pointer;"
                                    @click="close"
                                    v-if="closeIcon">
                                {{ icons.mdiClose }}
                            </v-icon>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <v-btn color="primary"
                           x-large
                           @click="save"
                           :disabled="newFileName === ''"
                           block>
                        {{ $t('Save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-row class="pa-16"
               v-if="success">
            <v-col lg="12"
                   class="text-center">
                <div class="secure-img">
                    <img src="@/assets/images/Success.png"
                         alt="" />
                </div>

                <p class="mt-3 font-weight-semibold text-color-black"
                   style="font-size: 22px">
                    {{ $t('Great!') }}
                </p>
                <p style="font-size: 14px"
                   class="mt-4">
                    {{ $t('Your ID details have been updated successfully.') }}
                </p>

                <div>
                    <v-btn color="primary"
                           class="submit return"
                           type="submit"
                           to="/"
                           large>
                        {{ $t('Return to Home') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AppHeader from '../test/AppHeader.vue'
import soapServices from '@/services/soap-services'
import store from '@/store'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services'
import alert from '@/views/alert.vue'
import BackAction from '@/@core/BackAction.vue'
import country from '@/@core/utils/country'
import city from '@/@core/utils/cities'

import soapErrorMessages from '@/@core/utils/soap-error-messages'
import { mdiClose } from '@mdi/js'
export default {
    components: {
        AppHeader,
        alert,
        BackAction,
    },

    data: () => ({
        icons: {
            mdiClose,
        },
        alertType: 'error',
        step: 1,
        code: [],
        country: [],
        cities: [],
        success: false,
        workEmail: '',
        homeEmail: '',
        city: '',
        workMobile: '',
        expiry: '',
        showLoader: false,
        lastFiveDigit: '',
        fileUploded: false,
        closeIcon: false,
        text: 'Back',
        output: false,
        showAlert: false,
        fullName: null,
        output: false,
        FileName: 'Max file size 5MB',
        nationality: null,
        legelId: null,
        countryCode: '',
        mobile: '',
        address1: '',
        address2: '',
        mailingCountry: '',
        information: '',
        mailZip: '',
        mailCountry: '',
        newFileName: '',
    }),
    mounted()
    {
        this.information = store.get('card/details')
        let personalDetail = store.get('accounts/clientDetail')
        this.legelId = this.information.card.cardDemog.legalId
        this.expiry = store.get('enrollment/expiryDate')
        this.fullName = this.information.cardMemberName
        this.eStmtEmail = personalDetail.primaryAddress?.email
        this.mobile = this.information.card.primaryAddress.phone1.phoneNumber
        this.homeEmail = this.information.card.primaryAddress.email ?? ''
        if (personalDetail.secondaryAddress?.secondaryAddressLength)
        {
            personalDetail.secondaryAddress.forEach(element =>
            {
                if (element.addressType == 'L6')
                {
                    this.workMobile = element?.phone1?.phoneNumber
                    this.workEmail = element.email
                    this.mailCountry = element.countryCode
                }
            })
        }
        personalDetail.keyValues.forEach(element =>
        {
            if (element.key == 'NationalityCountryName')
            {
                this.nationality = element.data
            }
        })
        this.mailZip = this.information.card.primaryAddress?.zipCode ?? '';
        this.address1 = this.information.card.primaryAddress?.addressLine1 ?? '';
        this.address2 = this.information.card.primaryAddress?.addressLine2 ?? '';
        this.city = this.information.card.primaryAddress?.cityFreeText ?? '';
    },

    methods: {
        save()
        {
            let expiry = ''
            this.information.card.cardCustomFields.forEach(element =>
            {
                if (element.fieldName == 'IDEXPIRYGREGORIAN')
                {
                    expiry = element.fieldValue
                }
            })
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custNo: store.get('requestKeys/ClientCode'),
                accNo: this.information.accountNo,
                cardNo: this.information.primeCardSerno,
                updateRequest: 'UIE',
                lang: 'E',
                nationality: this.information.card.cardDemog.nationalityCode ?? '',
                idNumber: this.information.card.cardDemog.legalId ?? '',
                idExpiry: expiry ?? '',
                eStmtEmail: this.eStmtEmail ?? '',
                mailMobile: `0${this.mobile}` ?? '',
                homeEmail: this.homeEmail ?? '',
                workMobile: this.workMobile ?? '',
                workEmail: this.workEmail ?? '',
                homeMobile: this.mobile ?? '',
                mailAddress1: this.address1 ?? '',
                mailAddress2: this.address2 ?? '',
                mailCity: this.city ?? '',
                mailCountry: this.mailCountry ?? '',
                mailZip: this.mailZip ?? '',
                homeTel1: '',
                homeTel2: '',
                workTel1: '',
                workTel2: '',
                suppMobile1: '',
                suppEmail1: '',
                suppMobile2: '',
                suppEmail2: '',
                suppMobile3: '',
                suppEmail3: '',
                suppMobile4: '',
                suppEmail4: '',
                suppMobile5: '',
                suppEmail5: '',
                suppMobile6: '',
                suppEmail6: '',
                suppMobile7: '',
                suppEmail7: '',
                suppMobile8: '',
                suppEmail8: '',
                suppMobile9: '',
                suppEmail9: '',
                clientMessage: '',
                fileName: this.newFileName,
            }

            soapServices.updateIdDetail(obj).then(soapResp =>
            {

                if (soapResp.SRPersonalInformationUpdateResponse?.SRPersonalInformationUpdateResult?.ServiceRequest?.Result.ErrorCode == '000')
                {
                    this.step = 2
                    window.scrollTo(0, 0)
                    let alertMessage = soapResp.SRPersonalInformationUpdateResponse?.SRPersonalInformationUpdateResult?.ServiceRequest?.Body.ResponseMsg;
                    let errorCode = soapResp.SRPersonalInformationUpdateResponse?.SRPersonalInformationUpdateResult?.ServiceRequest?.Result.ErrorCode;
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'success'
                    this.success = true;
                    this.showAlert = false;
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                } else
                {
                    let alertMessage = soapResp?.SRPersonalInformationUpdateResponse?.SRPersonalInformationUpdateResult?.ServiceRequest?.Result?.ErrorDesc;
                    let errorCode = soapResp.SRPersonalInformationUpdateResponse?.SRPersonalInformationUpdateResult?.ServiceRequest?.Result.ErrorCode;
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'error';
                    this.showAlert = true;
                    setTimeout(() =>
                    {
                        this.showAlert = false;
                    }, 5000)
                }
            })
        },

        chooseFiles: function ()
        {
            document.getElementById('fileUpload').click()
        },
        uploadImg(event)
        {
            let file = event.target.files[0]
            // const allowedExtensions = ['.jpg', '.png', '.pdf'];
            // const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            return new Promise((resolve, reject) =>
            {

                const allowedExtensions = ['.jpg', '.png', '.pdf'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                let fileSize = file.size / 1024 / 1024;
                if (fileSize <= 5)
                {
                    if (allowedExtensions.includes(fileExtension))
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = () =>
                        {
                            let fileName = file.name.replace(/[^a-zA-Z ]/g, "");

                            let base64Image = reader.result;
                            base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            this.uploadcardLimitImage(base64Image, file.size, fileName)
                        };
                    }
                    else
                    {
                        let alertMessage = 'Max file size: 5MB | Format: png, jpg and pdf'
                        this.alertMessage = alertMessage
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
                else
                {
                    let alertMessage = 'File is too large, maximum file size is 5MB'
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }


            })

        },
        close()
        {
            this.FileName = 'Max file size 5MB'
            this.fileUploded = false
            this.closeIcon = false
            this.newFileName = ''
            document.getElementById('fileUpload').value = null
        },
        uploadcardLimitImage(
            img,
            size,
            name, // xml request payload to soap-services
        )
        {
            this.showLoader = true
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: localStorage.getItem('userName'),
                base64Binary: img,
                filename: 'assets.jpg',
                length: size,
            }

            soapServices.changeCardLimit(obj).then(soapResp =>
            {
                if (soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Result?.ErrorCode == '000')
                {
                    this.newFileName = soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Body?.FileName
                    this.fileUploded = true
                    this.FileName = name
                    let alertMessage = 'File uploaded Successfully'
                    this.alertMessage = alertMessage
                    this.alertType = 'success'
                    this.showAlert = true
                    this.closeIcon = true
                    this.showLoader = false

                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.department {
    margin: 0px -14px -38px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 193px;
    background-color: #0a0808;
    color: white;
    /* text-align: center; */
    border-radius: 6px;
    padding: 0.1rem 0.5rem 0.3rem;
    height: 2rem;
    position: absolute;
    z-index: 1;
    font-size: -43rem;
    font-size: -5rem;
    text-align: start;
    top: -3rem;
    font-size: 0.6rem;
    left: 1rem;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.iqama {
    margin: 10px 0px;
}

.details {
    padding: 15px 0px 0px 0px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}</style>
