<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat>
                        <app-header :details="cardDetails"></app-header>
                        <div class="mx-8">
                            <v-row class="white-card-background">
                                <v-col cols="12"
                                       lg="4"
                                       md="5"
                                       sm="6">
                                       <div class="mt-5">
                                        <div style="margin-left: -18px;margin-right: -18px; margin-bottom: 13px;">
                                            <BackAction :text="text" :output="output"/> 
                                        </div>
                                    <p class="font-weight-semibold text-black text-h6 pl-3">{{ $t('Personal Details')
                                    }}</p>
                                       </div>
                                    <v-container class="details">
                                        <v-sheet class="pa-5 px-6 rounded pb-9 security">
                                            <div v-for="(item, index) in items"
                                                 :key="item.icon">
                                                <v-sheet :class="{ 'selected': select === index }"
                                                         class="pa-3 rounded-lg mb-2 pointer"
                                                         @click="showComponent(item, index)">
                                                    <v-avatar color="#E2F1FD"
                                                              size="25">
                                                        <v-icon color="#006FCF"
                                                                size="15">{{ item.icon }}</v-icon>
                                                    </v-avatar>
                                                    <span :class="{ 'text-white': select === index, 'ml-2': selectedLanguage == 'en', 'mr-2': selectedLanguage == 'ar' }"
                                                          class="text-caption font-weight-medium">{{ $t(item.text)
                                                          }}</span>
                                                    <v-icon v-if="selectedLanguage == 'en'"
                                                            class="chevron-right"
                                                            :class="{ 'selected': select === index }">
                                                        {{ item.icon2 }}</v-icon>
                                                    <v-icon v-if="selectedLanguage == 'ar'"
                                                            class="chevron-right"
                                                            :class="{ 'selected': select === index }">
                                                        {{ item.iconAr }}</v-icon>
                                                </v-sheet>
                                            </div>


                                        </v-sheet>
                                    </v-container>
                                </v-col>
                                <v-col cols="12"
                                       lg="8"
                                       md="7"
                                       v-if="showIdDetail">
                                    <IdDetail class="mt-1"></IdDetail>
                                </v-col>

                                <v-col cols="12"
                                       lg="8"
                                       md="7"
                                       sm="6"
                                       v-if="showPersonalDetail">
                                    <personalInformation></personalInformation>
                                </v-col>
                            </v-row>


                        </div>
                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>


<script>
import personalInformation from './PersonalInfo.vue';
import IdDetail from './IdDetail.vue';
import store from '@/store';
import AppHeader from '../test/AppHeader.vue';
import BackAction from '@/@core/BackAction.vue';

export default {
    name: 'Home',
    components: {
        personalInformation,
        IdDetail,
        AppHeader,
        BackAction
    },
    data()
    {
        return {
            select: 0,
            selected: null,
            selectedLanguage: 'en',
            showPersonalDetail: true,
            showIdDetail: false,
            cardDetails: null,
            text: 'Back to Cards',
            output: false,
            items: [
                { icon: 'mdi-email', text: 'Personal Details', iconAr: 'mdi-chevron-left', icon2: 'mdi-chevron-right', showPersonalDetail: 'true' },
                { icon: 'mdi-phone', text: 'ID Details', icon2: 'mdi-chevron-right', iconAr: 'mdi-chevron-left', showIdDetail: 'true' },
            ],
        }
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        this.cardDetails = store.get('card/details');
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
    },
    methods: {
        showComponent(item, index)
        {

            this.select = index;
            this.selected = null;
            this.showIdDetail = item.showIdDetail;
            this.showPersonalDetail = item.showPersonalDetail;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-color {
    color: #016FD0
}

.font-size {
    font-size: 10px;
    font-weight: 450;
}

.details {
    padding: 11px 0px 0px;
}

.pointer {
    cursor: pointer;
}

.text-white {
    color: white;
}

.selected {
    color: white !important;
    background-color: #016FD0;
}
</style>











