<template>
    <v-container class="details">
        <div v-if="showAlert && !success">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>
        <v-sheet v-if="!otp && !success"
                 class="pa-4 pb-7 mt-lg-16 mt-md-10 mt-sm-4 mt-3 px-6 rounded security">
            <v-form ref="infoForm"
                    class="multi-col-validation"
                    lazy-validation
                    v-model="valid">
                <div style="font-size: 14px"
                     class="font-weight-bold text-color-black pb-4 pt-1">
                    {{ $t('Personal information') }}
                </div>
                <v-row class="department">
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Full Name') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      placeholder="Enter Full Name"
                                      v-model="fullName"
                                      :readonly="true"
                                      solo
                                      hide-details="auto"
                                      class="mt-2"></v-text-field>
                    </v-col>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Nationality') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      placeholder="Nationality"
                                      :readonly="true"
                                      v-model="nationalityCountryTemp"
                                      hide-details="auto"
                                      solo
                                      class="mt-2"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-12 mx-0">
                    <span style="font-size: 14px"
                          class="font-weight-bold text-color-black">
                        {{ $t('Contact information') }}
                    </span>
                </v-row>

                <v-row class="mt-7">
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Country Code') }}
                        </div>

                        <v-text-field outlined
                                      type="text"
                                      :readonly="true"
                                      placeholder="Nationality"
                                      v-model="countryCode"
                                      hide-details="auto"
                                      class="mt-2"></v-text-field>
                    </v-col>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Mobile Number') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      :placeholder="$t('Enter Mobile Number')"
                                      v-model="mobile"
                                      :rules="mobileRule"
                                      v-mask="'##########'"
                                      placeholder="05xxxxxxxx"
                                      solo
                                      hide-details="auto"
                                      class="mt-1">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Email') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      v-model="email"
                                      :rules="emailRule"
                                      solo
                                      :placeholder="$t('Enter Email')"
                                      hide-details="auto"
                                      class="mt-1">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <div style="font-size: 14px"
                      class="font-weight-bold text-color-black mt-5">
                    {{ $t('Mailing Address') }}
                </div>
                <v-row class="mt-1">
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Address Line 1') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      :placeholder="$t('Enter Address Line 1')"
                                      v-model="address1"
                                      :rules="hyphenRestrict"
                                      solo
                                      hide-details="auto"
                                      class="mt-2">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Address Line 2 (Optional)') }}
                        </div>
                        <v-text-field outlined
                                      type="text"
                                      :placeholder="$t('Enter Address Line 2')"
                                      v-model="address2"
                                      hide-details="auto"
                                      solo
                                      class="mt-2">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('Country') }}
                        </div>

                        <v-text-field outlined
                                      type="text"
                                      :placeholder="$t('Country')"
                                      v-model="mailingCountryTemp"
                                      hide-details="auto"
                                      solo
                                      class="mt-2"></v-text-field>

                        <!-- <v-select :items="country"
                              outlined
                              v-model="mailingCountry"
                              :readonly="true"
                              class="mt-3"
                              solo
                              :label="$t('Select Country')"></v-select> -->
                    </v-col>
                    <v-col cols="12"
                           lg="5"
                           md="12"
                           sm="12">
                        <div style="font-size: 12px"
                             class="font-weight-bold text-color-black">
                            {{ $t('City') }}
                        </div>
                        <v-select :items="cities"
                                  solo
                                  outlined
                                  v-model="city"
                                  class="mt-3"
                                  :label="$t('Select City')"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                           lg="4"
                           md="6"
                           sm="12">
                        <v-btn color="primary"
                               x-large
                               :disabled="!valid"
                               @click="nextStep"
                               block>
                            {{ $t('Save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-sheet>

        <v-sheet v-if="otp && !success"
                 class="pa-4 pb-7 mt-16 px-6 rounded security">
            <v-row>
                <v-col cols="12"
                       lg="12"
                       md="6"
                       sm="6">
                    <p class="font-weight-semibold text-color-black mx-1"
                       style="font-size: 17px">
                        {{ $t('Enter 5-digit code sent to you') }}
                    </p>
                    <p class="text-color-black mx-1"
                       style="font-size: 12px">
                        {{
                            $t(`Please enter 5-digits verification code sent to you over
                        SMS`)
                        }}
                    </p>
                    <v-row class="">
                        <v-col lg="6"
                               dir="ltr">
                            <v-otp-input v-model="lastFiveDigit"
                                         :rules="cards"
                                         @input="onOtpInput"
                                         @blur="onInputBlur"
                                         ref="otpInput"
                                         :hide-details="true"
                                         type="number"
                                         onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                         length="5">
                            </v-otp-input>
                            <div v-if="otpError"
                                 style="font-size: 12px"
                                 class="error-message">
                                {{ $t(otpErrorMessage) }}
                            </div>
                        </v-col>
                    </v-row>
                    <div class="mt-4">
                        <timer :formattedTime="formattedTime"
                               :selectedLanguage="selectedLanguage"
                               @retry="sendOtpAgain"></timer>
                    </div>
                </v-col>
            </v-row>
            <div class="mt-5">
                <v-btn type="button"
                       @click="save"
                       color="primary"
                       :disabled="lastFiveDigit.length < 5"
                       class="py-4 px-md-16 ml-2">
                    {{ $t('Save') }}
                </v-btn>
            </div>
        </v-sheet>

        <v-row class="pa-16"
               v-if="success">
            <v-col lg="12"
                   class="text-center">
                <div class="secure-img">
                    <img src="@/assets/images/Success.png"
                         alt="" />
                </div>

                <p class="mt-3 font-weight-semibold text-color-black"
                   style="font-size: 22px">
                    {{ $t('Great!') }}
                </p>
                <p style="font-size: 14px"
                   class="mt-4">
                    {{ $t('Your request to update your personal details has been submitted successfully.') }}
                </p>

                <div>
                    <v-btn color="primary"
                           class="submit return"
                           type="submit"
                           to="/"
                           large>
                        {{ $t('Return to Home') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import timer from '@/@core/timer.vue'
import AppHeader from '../test/AppHeader.vue'
import soapServices from '@/services/soap-services'
import store from '@/store'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services'
import alert from '@/views/alert.vue'
import BackAction from '@/@core/BackAction.vue'
import country from '@/@core/utils/country'
import city from '@/@core/utils/cities'
import soapErrorMessages from '@/@core/utils/soap-error-messages'

export default {
    components: {
        AppHeader,
        alert,
        BackAction,
        timer
    },

    data: () => ({
        valid: false,
        step: 1,
        otp: false,
        emailRule: [
            v => !!v || 'Email is required',
            v => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v) || 'incorrect_email_format'
        ],
        mobileRule: [
            v => !!v || 'Mobile Number is required',
            value => /^05\d{8}$/.test(value) || 'Please enter a  mobile number consisting of 10 digits  (05xxxxxxxx)'
        ],
        hyphenRestrict: [
            v => !!v || 'Field is required',
            // v => /^[^-]*$/.test(v) || 'invalid_character'
        ],
        countryCodeTemp: '',
        code: [],
        showTimer: false,
        email: '',
        time: 60,
        timerId: null,
        success: false,
        country: [],
        cities: [],
        workEmail: '',
        homeEmail: '',
        city: '',
        workMobile: '',
        lastFiveDigit: '',
        text: 'Back',
        output: false,
        showAlert: false,
        fullName: null,
        output: false,
        fileName: null,
        nationality: null,
        legelId: null,
        countryCode: '',
        mobile: '',
        alertMessage: '',
        alertType: '',
        showAlert: false,
        address1: '',
        otpError: false,
        otpErrorMessage: '',
        cards: [value => !!value || 'Last 4 digits are required.'],
        address2: '',
        nationalityCountry: '',
        mailingCountry: '',
        mailingCountryAr: '',
        nationalityCountryAr: '',
        mailingCountryTemp: '',
        nationalityCountryTemp: '',
        information: '',
        mailZip: '',
        mailCountry: '',
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {


                this.selectedLanguage = mutation.payload;
                this.cities = city.getCity(this.selectedLanguage);

                if (this.selectedLanguage == 'en')
                {
                    this.nationalityCountryTemp = this.nationalityCountry;
                    this.mailingCountryTemp = this.mailingCountry;
                }
                else
                {
                    this.nationalityCountryTemp = this.nationalityCountryAr;
                    this.mailingCountryTemp = this.mailingCountryAr;
                }
                if (this.selectedLanguage == 'ar')
                {
                    let withoutSign = this.countryCodeTemp.replace('+', '');
                    this.countryCode = withoutSign + '+';
                }
                else
                {
                    this.countryCode = this.countryCodeTemp;
                }

                let contry = country.getCountry();

                contry.forEach(element =>
                {
                    this.country.push(element.name)
                    this.code.push(element.dialCode)
                })
            }
        })
    },
    mounted()
    {

        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = lang;
        }

        let workAdd1 = '';
        let workAdd2 = '';
        let homeAdd1 = '';
        let homeAdd2 = '';
        let workZip = '';

        let contry = country.getCountry();

        this.cities = city.getCity(this.selectedLanguage);

        contry.forEach(element =>
        {
            this.country.push(element.name)
            this.code.push(element.dialCode)
        })

        // this.information = store.get('card/details');

        this.$route.params.cardType == 'primary'
            ? (this.information = store.get('card/details'))
            : (this.information = store.get('card/supplementory'))

        console.log('this.informationthis.informationthis.information', this.information)

        let personalDetail = store.get('accounts/clientDetail');

        console.log('this.informationthis.informationthis.information', personalDetail)

        this.fullName = this.information.cardMemberName;

        this.email = this.information.card.primaryAddress.email; // eStatEmail

        // this.countryCode = '+' + this.information.card.primaryAddress.countryCode
        this.mobile = this.information.card.primaryAddress.phone1.phoneNumber;
        this.homeEmail = personalDetail.primaryAddress.phone1.phoneNumber ?? ''; // user

        homeAdd1 = personalDetail.primaryAddress?.addressLine1 ?? '';
        homeAdd2 = personalDetail.primaryAddress?.addressLine2 ?? '';

        if (personalDetail.secondaryAddress?.secondaryAddressLength)
        {
            personalDetail.secondaryAddress.forEach(element =>
            {
                if (element.addressType == 'L6')
                {
                    workAdd1 = element.addressLine1;
                    workAdd2 = element.addressLine2;
                    this.workMobile = element?.phone1?.phoneNumber;
                    this.workEmail = element.email;
                    this.mailCountry = element.countryCode;
                    workZip = element.zipCode;
                }
            })
        }
        let homeZip = personalDetail.primaryAddress?.zipCode ?? ''; // user // not in API on mobile

        this.mailZip = this.information.card.primaryAddress?.zipCode ?? ''; // card
        // this.mailZip = this.information.card.primaryAddress?.zipCode ?? homeZip ?? workZip ?? ''; // card

        this.address1 = this.information.card.primaryAddress?.addressLine1 ?? homeAdd1 ?? workAdd1 ?? '';
        this.address2 = this.information.card.primaryAddress?.addressLine2 ?? homeAdd2 ?? workAdd2 ?? '';

        personalDetail.keyValues.forEach(element =>
        {
            if (element.key == 'PrimCountryName')
            {
                this.nationality = element.data;

                this.mailingCountry = element.data;
                this.mailingCountryAr = country.getArabicSingleCountry(element.data);

                if (this.selectedLanguage == 'en')
                {
                    this.mailingCountryTemp = element.data;
                }
                else
                {

                    this.mailingCountryTemp = country.getArabicSingleCountry(element.data);
                }
            }

            if (element.key == 'NationalityCountryName')
            {
                this.nationality = element.data;

                this.nationalityCountry = element.data;
                this.nationalityCountryAr = country.getArabicSingleCountry(element.data);

                if (this.selectedLanguage == 'en')
                {
                    this.nationalityCountryTemp = element.data;
                }
                else
                {

                    this.nationalityCountryTemp = country.getArabicSingleCountry(element.data);
                }
            }
        })
        // let homeCity = personalDetail?.primaryAddress?.cityFreeText ?? ''
        this.city = this.information.card.primaryAddress?.cityCode == '00003' ? 'Riyadh' : ''
        contry.forEach(element =>
        {
            if (element.name == this.mailingCountry)
            {
                this.countryCode = element.dialCode;
                this.countryCodeTemp = element.dialCode;

                if (this.selectedLanguage == 'ar')
                {
                    let withoutSign = this.countryCodeTemp.replace('+', '');
                    this.countryCode = withoutSign + '+';
                }
                else
                {
                    this.countryCode = this.countryCodeTemp;
                }
            }
        });
    },
    computed: {
        formattedTime()
        {
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${seconds.toString().padStart(2, '0')}`;
        },
    },
    methods: {
        removeHyphens()
        {
            // Remove hyphens from the input
            // this.address2 = this.address2.replace(/-/g, '');
            // this.address1 = this.address1.replace(/-/g, '');
        },
        onKeyDown(event)
        {
            // Prevent the entry of hyphens
            // this.address2 = this.address2.replace(/-/g, '');
            // this.address1 = this.address1.replace(/-/g, '');
            // if (event.key === '-')
            // {
            //     event.preventDefault();
            // }
        },
        onOtpInput()
        {
            if (this.lastFiveDigit.length !== 5)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 5 digits are required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false;
            }
        },
        onInputBlur()
        {
            if (!this.lastFiveDigit.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 5 digits are required.';
            }
        },
        nextStep()
        {
            this.$refs.infoForm.validate();
            if (this.$refs.infoForm.validate())
            {
                this.sendOtpAgain()
            }
        },
        sendOtpAgain()
        {
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: localStorage.getItem('userName'),
                custSerNo: store.get('requestKeys/ClientCode'),
                cardSserno: this.information.primeCardSerno,
            }
            soapServices.otpCard(obj).then(soapResp =>
            {
                if (soapResp?.RequestAuthCodeResponse?.RequestAuthCodeResult?.Result?.ErrorCode == '000')
                {
                    window.scrollTo(0, 0)
                    this.otp = true
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Body.ResponseMsg
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'success'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                    this.showTimer = true
                    this.timerId = setInterval(() =>
                    {
                        this.time -= 1
                        if (this.time <= 0)
                        {
                            clearInterval(this.timerId)
                            this.showTimer = false
                            this.time = 60
                            this.timerId = null
                        }
                    }, 1000)
                } else
                {
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Resp.Result.ErrorDesc
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        save()
        {
            let expiry = ''
            this.information.card.cardCustomFields.forEach(element =>
            {
                if (element.fieldName == 'IDEXPIRYGREGORIAN')
                {
                    expiry = element.fieldValue
                }
            })
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custNo: store.get('requestKeys/ClientCode'),
                accNo: this.information.accountNo,
                cardNo: this.information.primeCardSerno,
                updateRequest: 'USC',
                lang: 'E',
                nationality: this.information.card.cardDemog.nationalityCode ?? '',
                idNumber: this.information.card.cardDemog.legalId ?? '',
                idExpiry: expiry ?? '',
                eStmtEmail: this.email ?? '', // required
                mailMobile: `0${this.mobile}` ?? '', // required
                homeEmail: this.homeEmail ?? '', // required
                workMobile: this.workMobile ?? '', // required
                workEmail: this.workEmail ?? '', // required
                homeMobile: `0${this.mobile}` ?? '', // required
                mailAddress1: this.address1 ?? '',
                mailAddress2: this.address2 ?? '',
                mailCity: this.city ?? '',
                mailCountry: this.mailCountry ?? '',
                mailZip: this.mailZip ?? '', // required
                homeTel1: '',
                homeTel2: '',
                workTel1: '',
                workTel2: '',
                // suppMobile1: this.$route.params.cardType == 'primary' ? '': `0${this.mobile}`, // required when supp card selected
                // suppEmail1: this.$route.params.cardType == 'primary' ? '': this.email, // required when supp card selected
                suppMobile1: '', // required when supp card selected
                suppEmail1: '', // required when supp card selected
                suppMobile2: '',
                suppEmail2: '',
                suppMobile3: '',
                suppEmail3: '',
                suppMobile4: '',
                suppEmail4: '',
                suppMobile5: '',
                suppEmail5: '',
                suppMobile6: '',
                suppEmail6: '',
                suppMobile7: '',
                suppEmail7: '',
                suppMobile8: '',
                suppEmail8: '',
                suppMobile9: '',
                suppEmail9: '',
                clientMessage: '',
                fileName: '',
                authCode: this.lastFiveDigit,
            }

            soapServices.updateProfileDetail(obj).then(soapResp =>
            {
                if (
                    soapResp.SRPersonalInformationUpdateWithAuthCodeResponse.SRPersonalInformationUpdateWithAuthCodeResult
                        .ServiceRequest.Result.ErrorCode == '000'
                )
                {
                    let alertMessage =
                        soapResp.SRPersonalInformationUpdateWithAuthCodeResponse.SRPersonalInformationUpdateWithAuthCodeResult
                            .ServiceRequest.Result.ErrorDesc
                    let errorCode =
                        soapResp.SRPersonalInformationUpdateWithAuthCodeResponse?.SRPersonalInformationUpdateWithAuthCodeResult
                            ?.ServiceRequest?.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'success'
                    this.success = true
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                } else
                {
                    let alertMessage =
                        soapResp?.SRPersonalInformationUpdateWithAuthCodeResponse?.SRPersonalInformationUpdateWithAuthCodeResult
                            ?.ServiceRequest?.Result?.ErrorDesc
                    let errorCode =
                        soapResp.SRPersonalInformationUpdateWithAuthCodeResponse?.SRPersonalInformationUpdateWithAuthCodeResult
                            ?.ServiceRequest?.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        goback()
        {
            window.history.back()
            window.scrollTo(0, 0)
        },

        back()
        {
            window.history.back()
            this.$emit('back')
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
    background: white;
}

.referesh-btn {
    padding: 8px 0px 10px;
    border-radius: 5px;
    border: 1px solid #016fd0 !important;
}

.email-text {
    margin: -39px 0px 30px;
}

.details {
    padding: 15px 0px 0px 0px;
}

.department {
    margin: 0px -14px -38px;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}
</style>
